@import 'apps/kargoru-web/src/scss/material.variables';
@import 'apps/kargoru-web/src/scss/app.variables';

.mat-stepper-vertical--custom {
  .mat-step-header {
    pointer-events: none !important;
  }

  .mat-vertical-stepper-content {
    visibility: visible !important;
    height: 100% !important;
  }
}

.mat-step-label {
  font-size: $font-size-h6 !important;
  &-selected {
    color: map-get($primary, 400) !important;
  }
}
