/*
<div class="backButton">
  <div class="flex align-items-center">
    <button mat-icon-button type="button"(click)="location.back()">
      <mat-icon>keyboard_backspace</mat-icon>
     </button>
     <h1>LABEL</h1>
  </div>
</div>
*/

.backButton {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  h1 {
    color: #000000;
    font-size: 1em;
    font-weight: 400;
    margin: 0;
  }
}
