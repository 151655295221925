@import "apps/kargoru-web/src/scss/app.variables";
@import "apps/kargoru-web/src/scss/material.variables";

.mat-form-field {
  width: 100%;
  &.mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      margin: 0;
    }
    .mat-form-field-infix {
      padding-bottom: 0.6em;
      border-top-width: 0px;
    }
    .mat-form-field-suffix,
    .mat-form-field-prefix {
      top: 0.4em;
    }

    .mat-select-arrow-wrapper {
      transform: translateY(0%);
    }
  }
}

label {
  font-weight: 500;
  margin-right: 8px;
}

input {
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 13px;
}

.mat-radio {
  &-button {
    margin-right: 2em;
  }
  &-label {
    &-content {
      font-size: $font-size-h6 !important;
      color: map-get($mat-gray, 800);
    }
  }
}

.mat-input-element,
.mat-select-placeholder {
  font-size: 1rem !important;
  color: #424242;
}
