mat-tab-group {
  font-family: Poppins, sans-serif !important;
  .mat-tab-header {
    border: none;
    background: #c4c4c4;
  }
  .mat-ink-bar {
    top: 0;
    height: 3px;
  }
  .mat-tab-label {
    font-family: Poppins, sans-serif !important;
    font-weight: 400;
    background: #c4c4c4;
    text-transform: uppercase;
    &.mat-tab-label-active {
      background: white;
      opacity: 1;
    }
  }

  mat-tab-body {
    background: white;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    .mat-tab-body-content {
      padding: 20px 30px;
    }
  }
}
