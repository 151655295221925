mat-table {
  margin: 10px;
}
mat-header-row {
  min-height: 44px !important;
}
mat-header-cell {
  font-size: 12px !important;
}
mat-header-cell,
mat-cell {
  &.small-cell {
    word-wrap: break-word !important;
    white-space: unset !important;
    flex: 0 0 8% !important;
    width: 8% !important;
    word-break: break-word;
  }
  &.status-cell {
    width: 15%;
    flex: 0 0 15%;
  }
}

mat-row {
  border-bottom-width: 0px !important;
  border-radius: 4px;
  transition: all 0.3s;
  border: none;
  &:nth-child(even) {
    background-color: #fff;
  }
  &:nth-child(odd) {
    background-color: rgba(196, 196, 196, 0.1);
  }
  &:hover {
    box-shadow: 0px 3px 8px rgba(33, 44, 69, 0.1);
    transform: scale(1.01);
    .action-icons {
      opacity: 1;
    }
  }
  .action-icons {
    background-color: #d1e7f1;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    color: #4e4e4e;
    /* opacity: 0; */
    transition: all 0.3s;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:last-child {
      margin-right: 0px;
    }
  }
}
