@import "apps/kargoru-web/src/scss/mixins/breakpoints";
@import "apps/kargoru-web/src/scss/app.variables";

.mat-toolbar {
  &.blue-header {
    background-image: url(/assets/backgrounds/geometric-blue.png);
    background-size: cover;
    height: 150px;
  }
}

kargoru-step-one .mat-list-base .mat-list-item .mat-list-item-content {
  flex-wrap: wrap;
  flex-direction: column;
  align-items: inherit;
  padding: 0;
}

@include media-breakpoint-up(md) {
  kargoru-step-one .mat-list-base .mat-list-item .mat-list-item-content {
    flex-direction: row;
    flex-wrap: nowrap;
    & > .spacer {
      width: 100px;
      min-width: 100px;
    }
  }

  kargoru-step-one .mat-form-field {
    max-width: 100px;
  }
}

.select-section {
  .mat-button-wrapper {
    display: flex;
    flex-direction: column;
  }
}

kargoru-profile {
  .mat-list-item-content {
    box-shadow: 0px -1px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 3px;
  }
  .mat-list-base .mat-list-item .mat-list-item-content,
  .mat-list-base .mat-list-option .mat-list-item-content {
    padding-left: 0;
    padding-right: 0;
  }
  .mat-card .mat-divider-horizontal {
    display: none;
  }
}
.expand-sidebar {
  height: 84px !important;
  cursor: pointer;
}
kargoru-menu {
  .new-quote-button {
    background-image: url("../../assets/images/new-quoute-sidebar.svg");
    width: auto;
    height: 60px;
    border: none;
  }
  .expanded-quote-button {
    background-color: #2d9acf;
    height: 46px;
    border: none;
    width: 174px;
  }
  .option-name {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: white !important;
    padding-left: 10px;
  }
}
.padding-left-items {
  padding-left: 35%;
}
.minor-padding-left-items {
  padding-left: 30%;
}
.arrow-extension {
  width: 0px;
  height: 20px;
  border: 1px solid #ffffff;
  margin-left: 3px;
  margin-right: 3px;
}
.display-flex {
  display: flex;
}
.plus-button {
  width: 22px;
  height: 22px;
  background: #227fac;
  border-radius: 4px;
  margin: 0px 12px;
  color: #ffffff;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  padding: 2px 6px 2px 7px;
}
.collapse-icon {
  color: #ffffff;
  margin-left: 15%;
}
.padding-left-icon {
  padding-left: 3%;
}
