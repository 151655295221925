/* $header
 ------------------------------------------*/
mat-toolbar {
  .toolbar-avatar {
    width: 40px;
    height: 40px;
    line-height: 24px;
  }

  .toolbar-avatar img {
    width: 40px;
    border-radius: 50%;
  }

  .notification-label {
    position: absolute;
    top: 0;
    left: 50%;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    background-color: mat-color($warn);
    border: 4px solid mat-color($warn);
    color: white;
    text-align: center;
  }

  &.main-header {
    padding: 0 8px;
    position: relative;
    z-index: 9;
    height: 84px;
    background-color: white;
    color: #000000;
    font-family: "Poppins";

    .button-container {
      flex: 1;
      display: flex;
      justify-content: center;
      .main-button {
        border: 1px solid #2d9acf;
        color: #2d9acf;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        height: 45px;
        width: 157px;
        box-sizing: border-box;
        filter: drop-shadow(0px 4px 12px rgba(45, 154, 207, 0.1));
        border-radius: 4px;
      }
      .bell-button {
        border: none;
        background-repeat: no-repeat;
        background-color: transparent;
        height: 45px;
        width: 45px;
        border-radius: 4px;
      }
      .profile-image {
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        background-image: url(../../assets/images/profile-image.svg);
        filter: drop-shadow(0px 3.16px 9.48px rgba(45, 154, 207, 0.1));
        border-radius: 50%;
        margin-right: 4px;
      }
      .user-profile {
        cursor: pointer;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        display: inline-flex;
      }
      .navbar-center {
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
  }
}

// Specific height for mobile devices in portrait mode.
@media ($mat-xsmall) and (orientation: portrait) {
  mat-toolbar {
    &.main-header {
      .branding {
        padding: 0 16px 0 16px;
      }

      .logo {
        width: 87px;
        height: 20px;
        -webkit-background-size: 87px 20px;
        background-size: 87px 20px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  mat-toolbar {
    &.main-header {
      .button-container {
        justify-content: flex-end;
      }
    }
  }
}
