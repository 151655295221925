@import 'apps/kargoru-web/src/scss/app.variables';

.form {
  &-phone-box {
    width: $field-width;
  }
}

.quote-form-column__item .mat-form-field-wrapper {
  padding-top: 10px;
}

.dynamic-table .mat-form-field-wrapper {
  max-width: 200px;
}
