.step {
  &-container {
    border-radius: 4px;
    background-color: #fafafa;
  }
  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &__field {
      flex: 2;
      display: flex;
      .mat-icon {
        margin-top: 0.5em;
        margin-left: 0.5em;
      }
      &:not(:first-child) {
        margin-left: 1em;
      }
    }
  }
  &-action-buttons {
    &-container {
      padding-top: 3em;
      display: flex;
      align-items: center;
      place-content: flex-end;
      background-color: #fafafa;
    }
    &__button {
      width: 120px;
      margin-left: 1em;
    }
  }
}
