$ps-bar-container-hover-bg: rgba(0, 0, 0, 0.582) !default;

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

/*
 * Scrollbar thumb styles
 */
.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

/* MS supports */
/*@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}*/

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

.ps__rail-y {
  right: 0;
  left: auto !important;
}

.ps__thumb-y {
  right: 2px;
  left: auto !important;
}
