@import "scss/material.variables";
@import "scss/material.themes";

// Core Variables and Mixins
@import "scss/app.variables";
@import "scss/mixins";

// Core Styles
@import "scss/core";
@import "scss/utilities";

// App Styles
@import "scss/app/auth";
@import "scss/app/forms";
@import "scss/app/mat-customization";
@import "scss/app/quote-request-steps";

// @import "primeng/resources/themes/lara-light-blue/theme.css";
// @import "primeng/resources/primeng.min.css";
// @import "primeicons/primeicons.css";

*:not(.material-icons):not(.pi) {
  font-family: Poppins, "sans-serif" !important;
}

.pi {
  font-size: 13px !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Poppins, "sans-serif";
}

h1.titlePage {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  font-family: Poppins, "sans-serif" !important;
  margin: 0;
}

.full-width {
  width: 100%;
}

.flex-auto {
  flex: auto;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(24, 28, 125, 0.62);
  z-index: 2;
}

.quote-request-section {
  margin-left: 16px;
  display: inline-grid;
  column-gap: 10px;
  grid-auto-columns: 250px;
  grid-auto-flow: column;
}

.quote-section__paragraph {
  margin: 2em 1em;
}

.quote__status {
  border-radius: 4px;
  padding: 2px 8px;
  text-transform: uppercase;
  font-size: 12px;
  &.created {
    color: #00cf84;
    background-color: #c7f1e2;
  }

  &.ready-for-quote {
    background: #d1e7f1;
    color: #2d9acf;
  }

  &.quoted {
    background: #d1e7f1;
    color: #2d9acf;
  }

  &.booked {
    color: #ee7b28;
    background-color: #fce5d4;
  }

  &.cancelled {
    color: #686868;
    background-color: #e5e5e5;
  }
}

.container-div {
  margin: auto;
}
.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.wrapper > div {
  flex: 1 1 200px;
}
.title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.img-100 {
  max-width: 100%;
}

//rich text editor
.nw-editor__res {
  img {
    max-width: 100%;
  }
}
