.radius-none {
  border-radius: 0 !important;
}

.radius-round {
  border-radius: $border-radius-base;
}

.radius-circle {
  border-radius: 50%;
}

.overflow-visible {
  overflow: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.block {
  display: block !important;
}

.button-block {
  display: block;
  width: 100%;
}

.inline-block {
  display: inline-block;
}

.center-block {
  display: block;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.flex-auto {
  flex: auto;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.shadow-none {
  box-shadow: none !important;
}

.border-none {
  border: 0 !important;
}

.background-none {
  background-color: transparent !important;
}

.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.spacer {
  flex: 1 1 auto;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 99999;
}
