.auth {
  width: 100%;
  height: 100%;
  display: flex;
  &-background {
    display: none;
  }
  &-section {
    margin-top: 150px;
    display: flex;
    justify-content: center;
    &__content {
      display: flex;
      flex-direction: column;
    }
    &__header {
      margin-bottom: $padding-huge;
      h1 {
        font-family: Poppins;
        font-weight: 500;
        font-size: 28px;
        margin-bottom: 8px;
        color: #000000;
      }
      h3 {
        margin: 0;
      }
    }
    &__form {
      display: flex;
      flex-direction: column;
      &__link {
        color: #2d9acf;
      }
      &__button {
        width: 50%;
      }
      &__footer {
        margin-top: $padding-huge;
        align-items: center;
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.phoneField {
  &__container {
    &-inputs {
      display: flex;
      #codeInput {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-right-width: 1px;
        width: 41%;
        .mat-form-field-flex {
          padding: 0 0.65em 0 0.65em;
        }
      }
      #numberInput {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-left-width: 1px;
        width: 59%;
      }
    }
  }
}
.back-button-link {
  margin: $padding-medium;
}
@include media-breakpoint-up(md) {
  .auth {
    &-background {
      display: block;
      background-image: url(/assets/backgrounds/login-1.jpg);
      width: 100%;
      height: 100%;
      background-size: cover;
    }
    &-section {
      min-width: 485px;
      margin-top: 224px;
    }
  }
}

@include media-breakpoint-up(md) {
  .register {
    &-background {
      display: block;
      background-image: url(/assets/backgrounds/registerbg.png);
      width: 100%;
      height: 100%;
      background-size: cover;
    }
    &-section {
      min-width: 485px;
      margin-top: 224px;
    }
  }
}
