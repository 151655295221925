// TODO: Find where the padding property is set for mat-card
$mat-card-padding: 16px;

$mat-pagination-height: 56px;

.mat-card {
  &.mat-card-table {
    padding: 10px;
  }

  .mat-card-table {
    padding: 0px;
    &__content {
      display: flex;
      flex: auto;
      flex-direction: column;
      overflow: hidden;
    }

    &__preview {
      display: flex;
      flex: auto;
      flex-direction: column;
      // padding-bottom: $mat-pagination-height;
      position: relative;
      width: 100%;
    }

    &__container {
      overflow-x: auto;
      margin-bottom: 60px;
    }

    &__grid {
      min-width: 600px;
    }

    &__footer {
      align-items: center;
      bottom: 0;
      position: absolute;
      width: 100%;
    }

    &__spinner-container {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      position: absolute;
      width: 100%;
    }
  }
}

.dynamic-table {
  .mat-form-field-wrapper {
    .mat-form-field-underline {
      background-color: white;
    }
  }
  .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: white;
  }
}
