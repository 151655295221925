@import "@angular/material/theming";
@import "material.variables";
@import "app.variables";

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($theme);
@include mat-base-typography($typography);
@include angular-material-typography($typography);
@include mat-core($typography);

.mat-h1,
.mat-headline,
.mat-typography h1 {
  color: map-get($primary, 400);
  font-size: $font-size-base;
  font-family: "Poppins", "sans-serif" !important;
  line-height: 36px;
  letter-spacing: 0.0018em;
}

.mat-body p,
.mat-body-1 p,
.mat-typography p {
  color: map-get($mat-gray, 800);
  font-size: $font-size-base;
  line-height: 28px;
  font-family: "Poppins", "sans-serif" !important;
  font-style: normal;
  font-weight: normal;
}

.mat-stepper-horizontal.disable-steps .mat-step-header {
  pointer-events: none !important;
}

.mat-form-field {
  width: $field-width;
}

.mat-button {
  font-size: 1rem;
}

.mat-expansion-panel-body {
  padding: 25px !important;
}
